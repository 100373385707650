import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { AppContext, AppProps } from 'next/app';
import * as Sentry from '@sentry/nextjs';

import * as gtag from "../lib/gtag";

Sentry.init({
  enabled: process.env.SENTRY_ENVIRONMENT !== 'localhost',
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.SENTRY_ENVIRONMENT,
});

const MyApp = ({ Component, pageProps, err }: AppProps & { err: Error }) => {
  const modifiedPageProps = { ...pageProps, err };

  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GA4}`}
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.GA4}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />

      <Component {...modifiedPageProps} />
    </>
  );
};

MyApp.getInitialProps = async ({ Component, ctx }: AppContext) => {
  let pageProps = {};
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }
  return { pageProps };
}

export default MyApp;
